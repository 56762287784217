




import { Prop, Component, Vue, Watch } from "vue-property-decorator";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { getters as gettersField } from "./observables/Field";

@Component({
  vuetify,
  i18n,
})
export default class SelectFields extends Vue {
  @Prop({ required: true }) cdCliente!: number;
  @Prop({ required: true }) unidades!: string;
  @Prop({ required: true }) grupo!: number;
  @Prop({ required: true }) locale!: string;
  @Prop({ default: "" }) token!: string;
  @Prop({ default: "" }) urlSgp!: string;
  @Prop({ default: "F" }) filterJustHighlight!: string;
  @Prop({ default: "[]" }) fieldsReceived!: string;
  @Prop({ default: null }) lngLatBounds!: null | string;
  @Prop({ default: "F" }) satteliteMode!: "F" | "T";
  @Prop({ default: "0px" }) offsetHeight!: string;
  @Prop({ default: "[]" }) operationsFields!: string;
  @Prop({ default: () => process.env.VUE_APP_BACK_SERVER_0 })
  urlServiceGo!: string;
  @Prop({ default: () => process.env.VUE_APP_TILE_SERVER_0 })
  urlTileServiceGo!: string;
  @Prop({ required: true }) structGff!: string;
  @Prop({ default: "" }) structNutrientCalc!: string;

  // @Prop({ default: 100004 }) cdCliente!: number;
  // @Prop({ default: "[81]" }) unidades!: string;
  // @Prop({ default: 0 }) grupo!: number;
  // @Prop({ default: "http://localhost:3000" }) urlServiceGo!: string;
  // @Prop({ default: "http://localhost:5000" }) urlTileServiceGo!: string;
  // @Prop({ default: "en-us" }) locale!: string;
  // @Prop({ default: "" }) token!: string;
  // @Prop({ default: "F" }) filterJustHighlight!: "F" | "T";
  // @Prop({ default: "" }) urlSgp!: string;
  // @Prop({ default: "[]" }) fieldsReceived!: string;
  // @Prop({ default: null }) lngLatBounds!: null | string;
  // @Prop({ default: "F" }) satteliteMode!: "F" | "T";
  // @Prop({ default: "0px" }) offsetHeight!: string;
  // @Prop({ default: "[]" }) operationsFields!: string;
  // @Prop({ default: "N" }) structGff!: string;
  // @Prop({ default: "" }) structNutrientCalc!: string;

  loaded = false;

  /* Computed */
  get mainLayout() {
    if (this.loaded) {
      return () => import("./components/Main.vue");
    }
    return null;
  }

  get propsDown() {
    return Object.assign({}, this.$props);
  }

  get selectedFields() {
    return gettersField.getSelectedFields();
  }

  /* Hooks */
  created() {
    this.loaded = true;
    this.injectStyle();
  }

  activated() {
    this.loaded = true;
  }

  deactivated() {
    this.loaded = false;
  }

  /* Methods */
  injectStyle() {
    const links = Array.from(document.querySelectorAll("link")).map(
      (n) => n.href
    );
    const fontUrl =
      "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
    if (links.indexOf(fontUrl) === -1) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = fontUrl;
      document.head.appendChild(link);
    }
  }

  close() {
    this.$emit("close");
  }

  @Watch("selectedFields", { deep: true }) onSelectedFields(
    newSelectedFields: Field.Field[]
  ) {
    this.$emit("emitSelectedFields", newSelectedFields);
  }
}
