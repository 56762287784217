import Vue from "vue";
import { getters as gettersGlobal } from "./Global";

export const state = Vue.observable({
  selectedFields: [] as Field.Field[]
});

export const getters = {
  getSelectedFields: () => state.selectedFields
};

export const mutations = {
  setSelectedFields: (selectedFields: Field.Field[]) => {
    state.selectedFields = selectedFields;
  }
};

export const actions = {
  buildStructNutrientCalcParams(initialChar: string) {
    const structNutrientCalc = gettersGlobal.getStructNutrientCalc();

    if (structNutrientCalc && structNutrientCalc.length > 0) {
      return `${initialChar}os=${structNutrientCalc.join("&os=")}`;
    }

    return "";
  },
  fetchFieldDescription(
    cdCliente: number,
    unidades: number[]
  ): Promise<GeoJSON.FeatureCollection> {
    const structNutrientCalcParams = actions.buildStructNutrientCalcParams("&");
    return gettersGlobal
      .getAxiosServiceGoInstance()
      .get(
        `/v4/desc_fields/units/${cdCliente}?u=${unidades.join(
          "&u="
        )}${structNutrientCalcParams}`
      )
      .then(result => result.data)
      .catch(error => {
        throw error;
      });
  },
  fetchUnityExtent(
    cdCliente: number,
    unidades: number[]
  ): Promise<[number, number, number, number]> {
    let url = "";
    if (unidades.length === 1) {
      const structNutrientCalcParams = actions.buildStructNutrientCalcParams(
        "?"
      );
      url = `/v1/extent/unidade/${cdCliente}/${unidades[0]}${structNutrientCalcParams}`;
    } else {
      const structNutrientCalcParams = actions.buildStructNutrientCalcParams(
        "&"
      );
      url = `/v4/extent/unidade/${cdCliente}?u=${unidades.join(
        "&u="
      )}${structNutrientCalcParams}`;
    }
    return gettersGlobal
      .getAxiosServiceGoInstance()
      .get(url)
      .then(result => result.data.flat())
      .catch(error => {
        throw error;
      });
  },
  fetchFieldsByList: (
    fields: number[],
    units: number[]
  ): Promise<Field.Field[]> | Field.Field[] => {
    if (fields && fields.length) {
      fields = fields.sort(function(a: number, b: number) {
        return a - b;
      });

      const structNutrientCalcParams = actions.buildStructNutrientCalcParams(
        "?"
      );

      return gettersGlobal
        .getAxiosServiceGoInstance()
        .post(
          `/v4/list-fields/${gettersGlobal.getCdCliente()}${structNutrientCalcParams}`,
          {
            units: units,
            fields: fields
          }
        )
        .then(result => result.data)
        .then((result: Field.Field[]) => result || [])
        .catch(error => {
          console.error(error);
          throw error;
        });
    } else {
      return [];
    }
  },
  searchUPFZT: (): Promise<Filter.FilterItemUnities[]> => {
    const unities = gettersGlobal.getUnidades().join("&u=");

    const structNutrientCalcParams = actions.buildStructNutrientCalcParams("&");

    return gettersGlobal
      .getAxiosServiceGoInstance()
      .get(
        `/v4/list-upfzt/${gettersGlobal.getCdCliente()}?u=${unities}${structNutrientCalcParams}`
      )
      .then(result => result.data)
      .then((result: Filter.FilterItemUnities[]) => result)
      .catch(error => {
        console.error(error);
        return [];
      });
  }
};
