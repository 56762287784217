import Vue from "vue";
import Vuetify, {
  VApp,
  VContent,
  VContainer,
  VRow,
  VCol,
  VBtn,
  VIcon,
  VCard,
  VCardText,
  VCardActions,
  VCardTitle,
  VList,
  VListItemGroup,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VListItemAction,
  VDivider,
  VAlert,
  VSnackbar
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
Vue.use(Vuetify, {
  components: {
    VApp,
    VContent,
    VContainer,
    VRow,
    VCol,
    VBtn,
    VIcon,
    VCard,
    VCardText,
    VCardActions,
    VCardTitle,
    VList,
    VListItemGroup,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VListItemAction,
    VDivider,
    VAlert,
    VSnackbar
  },
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#458F9A",
        secondary: "#6c6c6c",
        success: "#4caf50",
        warning: "#fb8c00",
        error: "#ff5252"
      }
    }
  }
});
