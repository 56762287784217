import Vue from "vue";
import axios, { AxiosInstance } from "axios";

export const state = Vue.observable({
  token: "" as string, // Token de autenticação necessário para requests no sgp
  unidades: [] as number[],
  cdCliente: 0 as number,
  grupo: 0 as number /* Grupo do servidor sendo 1 - USA, 2 - Cana e 3 - Grãos/Perenes */,
  locale: "en-us" as string,
  urlSgp: "" as string /* URL da API do SGP para comunicação */,
  urlServiceGo: "" as string /* URL da API para ações no banco do mapa */,
  urlTileServiceGo: "" as string /* URL da API para geração de talhões */,
  axiosSgpInstance: {} as AxiosInstance,
  axiosServiceGoInstance: {} as AxiosInstance,
  axiosTileServiceGoInstance: {} as AxiosInstance,
  structGFF: "" as string /* Se é a estrutura Grower, Farm e Field do EUA */,
  filterJustHighlight: "F" as
    | "F"
    | "T" /* Se o filtro vai selecionar os talhões ou apenas destacá-los */,
  fieldsReceived: [] as Field.Field[] /* Talhões que podem ser enviados pelo consumidor do web component como já selecionados */,
  lngLatBounds: null as null | mapboxgl.LngLatBoundsLike /* Campo de visão passado para o web component abrir */,
  satteliteMode: "F" as "F" | "T",
  isMapFetchingData: false,
  operationsFields: [] as number[],
  structNutrientCalc: null as Array<number> | null
});

export const getters = {
  getToken: () => state.token,
  getUnidades: () => state.unidades,
  getCdCliente: () => state.cdCliente,
  getGrupo: () => state.grupo,
  getLocale: () => state.locale,
  getUrlSgp: () => state.urlSgp,
  getUrlServiceGo: () => state.urlServiceGo,
  getUrlTileServiceGo: () => state.urlTileServiceGo,
  getAxiosSgpInstance: () => state.axiosSgpInstance,
  getAxiosServiceGoInstance: () => state.axiosServiceGoInstance,
  getAxiosTileServiceGoInstance: () => state.axiosTileServiceGoInstance,
  getStructGFF: () => state.structGFF,
  getFilterJustHighlight: () => state.filterJustHighlight,
  getFieldsReceived: () => state.fieldsReceived,
  getLngLatBounds: () => state.lngLatBounds,
  getSatteliteMode: () => state.satteliteMode,
  getIsMapFetchingData: () => state.isMapFetchingData,
  getOperationsFields: () => state.operationsFields,
  getStructNutrientCalc: () => state.structNutrientCalc
};

export const mutations = {
  setToken: (token: string) => {
    state.token = token;
  },
  setUnidades: (unidades: number[]) => {
    state.unidades = unidades;
  },
  setCdCliente: (cdCliente: number) => {
    state.cdCliente = cdCliente;
  },
  setGrupo: (grupo: number) => {
    state.grupo = grupo;
  },
  setLocale: (locale: string) => {
    state.locale = locale;
  },
  setUrlSgp: (urlSgp: string) => {
    state.urlSgp = urlSgp;
  },
  setUrlServiceGo: (urlServiceGo: string) => {
    state.urlServiceGo = urlServiceGo;
  },
  setUrlTileServiceGo: (urlTileServiceGo: string) => {
    state.urlTileServiceGo = urlTileServiceGo;
  },
  setAxios: () => {
    state.axiosSgpInstance = axios.create({
      baseURL: state.urlSgp,
      headers: {
        "Content-type": "application/json",
        "x-auth-token": state.token
      },
      timeout: 100000
    });
    state.axiosServiceGoInstance = axios.create({
      baseURL: state.urlServiceGo,
      headers: {
        "Content-type": "application/json",
        "X-Auth-Token": getters.getToken()
      },
      timeout: 100000
    });
    state.axiosTileServiceGoInstance = axios.create({
      baseURL: state.urlTileServiceGo,
      headers: {
        "Content-type": "application/json"
      },
      timeout: 100000
    });
  },
  setStructGFF: (structGFF: string) => {
    state.structGFF = structGFF;
  },
  setFilterJustHighlight: (filterJustHighlight: "F" | "T") => {
    state.filterJustHighlight = filterJustHighlight;
  },
  setFieldReceived: (fieldsReceived: Field.Field[]) => {
    state.fieldsReceived = fieldsReceived;
  },
  setLngLatBounds: (lngLatBounds: null | mapboxgl.LngLatBoundsLike) => {
    state.lngLatBounds = lngLatBounds;
  },
  setSatteliteMode: (satteliteMode: "F" | "T") => {
    state.satteliteMode = satteliteMode;
  },
  setIsMapFetchingData: (pIsLoading: boolean) => {
    state.isMapFetchingData = pIsLoading;
  },
  setOperationsFields: (pFields: number[]) => {
    state.operationsFields = pFields;
  },
  setStructNutrientCalc: (structNutrientCalc: Array<number> | null) => {
    state.structNutrientCalc = structNutrientCalc;
  }
};
